// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Change the hex values below to alter the main color scheme.

$blue: 			#007bff;
$indigo:		#6610f2;
$purple:		#6f42c1;
$pink:			#e83e8c;
$red:			#dc3545;
$orange:		#fd7e14;
$yellow:		#ffc107;
$green:			#28a745;
$teal:			#20c997;
$cyan:			#17a2b8;
$white:			#fff;
$gray:			#6c757d;
$gray-dark:		#343a40;

$logo-purple:	#001354;
$logo-orange:	#f69100;
$logo-green:	#009909;

$primary:       $logo-orange;
$primary-2:     $gray-dark;
$primary-3:     $logo-purple;

//$primary:       #009b72;
//$primary-2:     #046865;
//$primary-3:     #192d35;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);